.float-right {
  float: right;
  border-radius: 8px;
}

.label {
  margin-bottom: 0.5rem; /* Adjust this value as necessary */
}

.teacher-dropdown {
  /* min-width: 300px; */
  width: 25vw !important;
  border: 2px solid #0080b5 !important;
  border-radius: 1vh !important;
  font-size: 2vh !important;
  font-weight: 500 !important;
  /* padding-top: 1vh;
      padding-bottom: 1vh; */
}
