.signup-form-navigation-buttons {
  /* position: relative;
	bottom: 5vh;
	right: 5vh; */
  margin-left: 80%;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"] {
  /* min-width: 300px; */
  width: 25vw !important;
  border: 2px solid #0080b5;
  border-radius: 1vh;
  font-size: 2vh;
  font-weight: 500;
  /* padding-top: 1vh;
	padding-bottom: 1vh; */
}

.acceptTC {
  padding-left: 40px;
}

/* .acceptTC > * { */
/* .form-check-label {
	width: auto !important;
	margin-left: 10px;
} */

.roles {
  background-color: rgb(228 243 247);
  width: 100%;
  padding-bottom: 2%;
  padding-top: 2%;
  margin-bottom: 2%;
}

.roles-container .form-check-label {
  font-weight: 500;
}

.roles-container .form-check-input {
  border: 2px solid #0080b5;
}

.roles-container {
  width: 75% !important;
}

.password-fields {
  margin-top: 5%;
}

.signup-page-description-header {
  color: black !important;
  font-weight: 500 !important;
  /* width: 100%; */
}

.signup-page-description-bar {
  padding-top: 2%;
}

.signup-page-overview-container {
  bottom: 0;
  right: 0;
  margin-left: 2%;
  margin-right: 2%;
  background-color: white !important;
  min-height: 70%;
  border-radius: 3vh;
}

.signup-container-shape {
  margin-top: 12vh;
  margin-bottom: 5%;
  /* width: 80%; */
}

.signup-hr {
  margin: 0 !important;
  padding: 0 !important;
}
