.card-detail {
  min-height: 90%;
  width: 70%;
  background-color: white;
  text-align: center;
  border-radius: 4vh !important;
  margin-bottom: 10%;
}

.trial-card-detail {
  min-height: 90%;
  width: 70%;
  background-color: #f9b540 !important;
  text-align: center;
  border-radius: 4vh !important;
  margin-bottom: 10%;
}

.specialed-card-detail {
  min-height: 90%;
  width: 70%;
  background-color: #c777cd !important;
  text-align: center;
  border-radius: 4vh !important;
  margin-bottom: 10%;
}

/* New SET class styling */
.set-card-detail {
  min-height: 90%;
  width: 70%;
  background-color: #4caf50 !important; /* Nice green color */
  text-align: center;
  border-radius: 4vh !important;
  margin-bottom: 10%;
}

/* Ensure that the text colors inside the card are adjusted accordingly */
.set-card-detail .class-card-text,
.set-card-detail .grade-level-blue,
.set-card-detail .grade-level-purple,
.set-card-detail .trial-class-card-text,
.set-card-detail .specialed-class-card-text {
  color: white; /* Set text color to white for visibility */
}

.row-position {
  margin-top: 7% !important;
  margin-left: 5% !important;
}

.class-row-position {
  margin-left: 5% !important;
}

.class-card-img {
  height: 10vh;
  margin-top: 5%;
  color: #2c7693;
}

.trial-class-card-img {
  height: 10vh;
  margin-top: 5%;
  color: white;
}

.specialed-class-card-img {
  height: 10vh;
  margin-top: 5%;
  color: white;
}

/* New SET class image styling */
.set-class-card-img {
  height: 10vh;
  margin-top: 5%;
  color: white; /* Optional, depending on the icon used */
}

.trial-class-card-img:hover {
  color: #2c7693;
}

.trial-class-card-text,
.specialed-class-card-text,
.set-class-card-text {
  /* Combined styling */
  font-size: 1.2vw;
  font-weight: 700;
  margin-top: 5%;
  color: white;
}

.link {
  text-decoration: none;
  color: black;
  height: 100%;
}

.grade-level-blue {
  background-color: #5eb4cd;
  color: white;
  border-radius: 8px;
  align-items: center;
  text-align: center;
  margin-left: 20%;
  margin-right: 20%;
}

.grade-level-purple {
  background-color: #b662a6;
  color: white;
  border-radius: 8px;
  align-items: center;
  text-align: center;
  margin-left: 20%;
  margin-right: 20%;
}

.inline-container {
  display: flex;
  align-items: center; /* Align items vertically in the center */
}

.inline-container > * {
  margin-right: 10px;
}

.sandbox-button {
  background-color: #2c7693 !important;
  color: white !important;
  border-color: #2c7693 !important;
  margin-left: 12px !important;
  width: 100px !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px 0px !important;
  border-radius: 8px !important;
  border-bottom-style: solid !important;
  border-right-style: solid !important;
  font-weight: bold !important;
}

.additional-resources-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 200px;
  padding-right: -50px;
}

.special-ed-card-detail {
  background-color: #c777cd;
  color: white; /* or another color that contrasts well with the background */
}

/* Ensure that the text colors inside the special ed card are adjusted accordingly */
.special-ed-card-detail .class-card-text,
.special-ed-card-detail .grade-level-blue,
.special-ed-card-detail .grade-level-purple {
  color: white; /* Adjust as needed for visibility */
}
