.score-indicator {
    margin-top: 5%;
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
    color: white;
}

.existing-score {
    background-color: #ff6347;
    /* Red background for existing score */
}

.soon-score {
    background-color: #55c57a;
    /* Green background for soon-to-come score */
}

.recommendation-section {
    background-color: #fff5c6;
    padding: 20px;
    border-radius: 10px;
    flex-grow: 1;
}

.recommendation-section h3 {
    font-size: 1.5em;
    margin-bottom: 15px;
}

.recommendation-content {
    font-size: 1em;
}

.rti-details-section {
    padding: 20px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
}

.rti-details-section h3 {
    font-size: 1.5em;
    margin-bottom: 15px;
}

.start-button {
    margin-top: 10px;
    font-weight: bold;
}

.calendar-section {
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
}

.react-calendar {
    width: 100% !important;
}

.highlight-start {
    background-color: #ffa500 !important;
    color: white;
    position: relative;
  }
  
  .highlight-start::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    height: 80%;
    background: #ffa500;
    transform: translate(-50%, -50%) rotate(45deg); /* Diamond shape */
    z-index: -1;
    border-radius: 0;
  }
  
  .highlight-end {
    background-color: #ffa500 !important;
    color: white;
    position: relative;
  }
  
  .highlight-end::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    height: 80%;
    background: #ffa500;
    transform: translate(-50%, -50%) rotate(0); /* Hexagon-like shape */
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
    z-index: -1;
  }
  
  .highlight-range {
    background-color: rgba(255, 165, 0, 0.2) !important; /* Light transparent color */
  }
  
  .dim-weekend {
    color: #ccc !important;
  }
  