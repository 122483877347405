.qr-code-container {
    height: 100%;
    width: 100%;
    display: flex;
    /* justify-content: center; */
    /* border: 0.5px dashed grey; */
}

.qr-student-name-container{
    /* position: relative; */
    font-size: 100%;
    font-weight: 700;
    margin: 0 auto;
    text-align: center;
    margin-top: 5%;
    margin-bottom: 2.3%;
}


/* img {
	display: block;
	width: 480px;
	height: 480px;
	margin: 2rem auto;
}

.qr-generator {
	padding: 2rem;
}

h1 {
	font-size: 2rem;
	margin-bottom: 1rem;
}

input {
	appearance: none;
	outline: none;
	border: none;
	background: #EEE;
	width: 100%;
	max-width: 320px;
	padding: 0.5rem 1rem;
	border-radius: 0.5rem;
	margin-right: 1rem;
}

*/

/* button, a {
	appearance: none;
	outline: none;
	border: none;
	background: none;
	cursor: pointer;

	color: #2bcb4b;
	font-size: 1.5rem;
	text-decoration: none;
}  */