.accordion {
  margin-bottom: 15px; /* Space between each accordion */
  border-radius: 10px; /* Rounded edges for the accordion */
  overflow: hidden; /* Ensures the corners are rounded properly */
}

.accordion-header {
  background-color: #f8f9fa; /* Change background color if needed */
  border-radius: 10px 10px 0 0; /* Rounded edges for the header */
}

.accordion-body {
  border-radius: 0 0 10px 10px; /* Rounded edges for the body */
}

.accordion-item {
  margin-bottom: 15px; /* Space between each accordion */
  border-radius: 10px; /* Rounded edges for the accordion */
  overflow: hidden; /* Ensures the corners are rounded properly */
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.big-ball-spinner {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 12px solid #ffff;
  border-top-color: #ff4d00;
  animation: spin 1s infinite linear;
  z-index: 99999;
}
