@media print {
  /* @page {
    size: landscape;
  } */
}

.printout-logo {
  background-image: url(../../public/assets/imgs/Alpaca-logo-white-outline.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100px;
  height: 100px;
  position: relative;
  /* right: 75px; */
  top: -35px;
  z-index: 1;
}

.printout-page-description-header {
  color: #2c7693;
  font-weight: 700;
  font-size: 25px;
  /* width: 100%; */
}