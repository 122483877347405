.free-trial-banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    border: 1px solid #dfe3e8;
    border-radius: 8px;
    padding: 15px 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 800px !important;
    margin: 0 auto 20px;
    /* Centers the banner horizontally */
}

.banner-content {
    display: flex;
    align-items: center;
    flex-grow: 1;
    /* Expands to take available space, pushing .banner-buttons to the far right */
}

.banner-message {
    font-size: 1rem;
    color: #333;
    margin: 0;
}

.banner-buttons {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-left: auto;
    /* Ensures buttons are pushed to the far right */
}

/* Button Styling */
.extend-btn {
    color: #007bff;
    background-color: #ffffff;
    border: 1px solid #007bff;
    border-radius: 5px;
    font-weight: bold;
    padding: 6px 12px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.extend-btn:hover {
    background-color: #f0f8ff;
    color: #0056b3;
}

.purchase-btn {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    padding: 6px 12px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.purchase-btn:hover {
    background-color: #0056b3;
}



/* Modal Styling */
.custom-extend-modal .modal-content {
    border-radius: 12px;
    padding: 20px;
    text-align: center;
    max-width: 500px;
    margin: auto;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.1);
}

/* Header - Hide Border */
.custom-modal-header {
    border-bottom: none;
}

/* Modal Icon */
.modal-icon-container {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.modal-icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 2px solid #e6f3fb;
}

/* Trial End Notice */
.trial-end-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    gap: 8px;
}

.trial-end-badge {
    background-color: #e0f7e0;
    color: #28a745;
    font-size: 0.9rem;
    padding: 4px 8px;
    border-radius: 5px;
    font-weight: 500;
}

.trial-end-date {
    color: #6c757d;
    font-size: 0.9rem;
}

/* Modal Title */
.modal-title {
    font-size: 1.4rem;
    font-weight: bold;
    color: #333;
    margin-top: 10px;
}

/* Modal Subtitle */
.modal-subtitle {
    font-size: 1rem;
    color: #555;
    margin-top: 10px;
}

.contact-link,
.quote-link {
    color: #007bff;
    text-decoration: underline;
}

.contact-link:hover,
.quote-link:hover {
    color: #0056b3;
}

/* Footer Buttons */
.custom-modal-footer {
    display: flex;
    justify-content: center;
    gap: 15px;
    padding-top: 15px;
    border-top: none;
}

.apply-now-btn {
    color: #333;
    background-color: #ffffff;
    border: 1px solid #dfe3e8;
    padding: 8px 16px;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.apply-now-btn:hover {
    background-color: #f8f9fa;
}

.purchase-btn-modal {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 8px 16px;
    font-weight: bold;
    transition: background-color 0.3s;
}

.purchase-btn-modal:hover {
    background-color: #0056b3;
}


/* Purchase Modal Styling */
.custom-purchase-modal .modal-content {
    border-radius: 12px;
    padding: 20px;
    text-align: center;
    max-width: 500px;
    margin: auto;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.1);
}

/* Header - Hide Border */
.custom-modal-header {
    border-bottom: none;
}

/* Modal Icon */
.modal-icon-container {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.modal-icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 2px solid #e6f3fb;
}

/* Trial End Notice */
.trial-end-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    gap: 8px;
}

.trial-end-badge {
    background-color: #e0f7e0;
    color: #28a745;
    font-size: 0.9rem;
    padding: 4px 8px;
    border-radius: 5px;
    font-weight: 500;
}

.trial-end-date {
    color: #6c757d;
    font-size: 0.9rem;
}

/* Modal Title */
.modal-title {
    font-size: 1.4rem;
    font-weight: bold;
    color: #333;
    margin-top: 10px;
}

/* Modal Subtitle */
.modal-subtitle {
    font-size: 1rem;
    color: #555;
    margin-top: 10px;
    margin-bottom: 15px;
}

/* Button Container */
.purchase-button-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 15px;
}

/* Quote Button */
.quote-btn-modal {
    background-color: #ffffff;
    color: #333;
    border: 1px solid #dfe3e8;
    padding: 8px 16px;
    font-weight: bold;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 5px;
    transition: background-color 0.3s;
}

.quote-btn-modal:hover {
    background-color: #f8f9fa;
}

/* Purchase Button */
.purchase-btn-modal {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 8px 16px;
    font-weight: bold;
    transition: background-color 0.3s;
}

.purchase-btn-modal:hover {
    background-color: #0056b3;
}

/* Forward Section */
.forward-section {
    margin-top: 20px;
    text-align: left;
}

.divider {
    border: 0.5px solid #e0e0e0;
    margin: 20px 0;
}

.forward-message {
    font-size: 0.9rem;
    color: #666;
    text-align: center;
    margin-bottom: 10px;
    font-weight: bold;
}

/* Override global input styling within custom-purchase-modal */
.custom-purchase-modal .custom-input[type="email"] {
    width: 100% !important;
    /* Ensures full width within the modal */
    max-width: 100%;
    /* Prevents it from shrinking in smaller modals */
    padding: 8px;
    /* Add padding for better appearance */
    border: 1px solid #dfe3e8;
    /* Override the border */
    border-radius: 5px;
    /* Match the modal's style */
    font-size: 1rem;
    /* Override the font size */
    font-weight: normal;
    /* Override font weight */
}


.forward-btn-modal {
    display: block;
    width: 100%;
    margin-top: 15px;
    background-color: #007bff;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.3s;
    padding: 8px;
}

.forward-btn-modal:hover {
    background-color: #0056b3;
}

.expired-btn {
    background-color: #ff4500; /* Bright orange */
    color: #ffffff; /* White text */
    border: none; /* Remove border */
    border-radius: 5px; /* Rounded corners */
    font-weight: bold;
    padding: 10px 15px; /* Add padding for clickability */
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth hover effect */
}

.expired-btn:hover {
    background-color: #e63e00; /* Darker orange on hover */
}

.expired-btn:active {
    background-color: #cc3700; /* Even darker for click feedback */
}
