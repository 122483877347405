.masterdash-card-detail{
    /* height: 90%; */
    width: 95%;
    background-color: white;
    text-align: center;
    border-radius: 4vh !important;
    margin-bottom: 10%;
}

.masterdash-row-position {
    margin-top: 5% !important;
    margin-left: 5% !important;
}

.invite-colleagues-row-position{
    margin-top: 10% !important; 
}
/* adjust above margin depending on banner being present or not  */

.master-class-card-img {
  height: 7vw;
  margin-top: 5%;
  margin-bottom: 5%;
}

.class-card-text {
    font-size: 1.3vw !important;
    font-weight: 700;
    margin-top: 5%;
    color: #2c7693;
}

.ass-heading-card-text{
  width: 100%;
  font-size: 1.1vw !important;
  font-weight: 700;
  margin-top: 5%;
  color: #2c7693;
}

.link {
  text-decoration: none;
  color: black;
  height: 100%;
}

.grade-level {
  background-color: #5eb4cd;
  color: white;
  border-radius: 8px;
  align-items: center;
  text-align: center;
  margin-left: 20%;
  margin-right: 20%;
}

.masterdash-card-detail {
  position: relative;
}

/* .no-classes-overlay .overlay-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: none;
  } */


.hide-assessment-banner{
  display: none !important;
}

/* .assign-assessment-card {
  pointer-events: none;
} */

.disabled-card-wrapper {
  position: relative;
  display: flex;
  gap: 1rem;
  opacity: 0.6; /* Make components semi-transparent */
  filter: grayscale(100%); /* Apply grayscale effect */
  pointer-events: auto; /* Allow clicks on the wrapper */
  cursor: pointer; /* Show pointer to indicate interactivity */
}

.disabled-card-wrapper:hover {
  opacity: 0.8; /* Slight hover effect to mimic interactivity */
}

.disabled-card-wrapper::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(255, 255, 255, 0.5); Add a translucent overlay */
  z-index: 1; /* Ensure overlay is above the components */
}

.disabled-card-wrapper > * {
  pointer-events: none; /* Disable interactions for child components */
  z-index: 0; /* Ensure child components are below the overlay */
}


.disabled-card {
  opacity: 0.5; /* Make card appear disabled */
  cursor: not-allowed; /* Change cursor to indicate it's not clickable */
}

.disabled-link-overlay {
  pointer-events: none; /* This prevents clicking on the Link */
}

/* .disabled-link-overlay a {
  pointer-events: none;
} */

/* You might also want to apply a visual cue that the card is disabled, e.g., a not-allowed cursor on hover */
.masterdash-card-detail:hover {
  cursor: not-allowed;
}

.fun-vibrant-modal .modal-header {
  background-color: #5DBDD6; /* Vibrant header background */
  color: white; /* Text color for the title */
}

.fun-vibrant-modal .modal-footer button {
  background-color: #5DBDD6; /* Vibrant button background */
  border-color: #5DBDD6; /* Button border color */
  color: white; /* Button text color */
}

.fun-vibrant-modal .modal-content {
  border: 2px solid #5DBDD6; /* Vibrant border for the modal */
}

.fun-vibrant-modal .modal-footer button:hover {
  background-color: #4bc1d2; /* Slightly lighter on hover */
  border-color: #4bc1d2;
}

